@import 'src/utils/utils';

.up {
  @include Shadow-1;
  position: fixed;
  right: 8px;
  bottom: 80px;
  z-index: 9;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 10px;
  color: $Main-1;
  background-color: $Main-2;
  transition:
    visibility 0.3s,
    opacity 0.3s,
    color 0.3s;
  visibility: hidden;
  opacity: 0;
  outline: none;
  cursor: pointer;

  @media(min-width: $md) {
    right: 16px;
  }
  @media(min-width: $lg) {
    right: 16px;
    bottom: 60px;
    width: 44px;
    height: 44px;
  }
  @media(min-width: $xxl) {
    right: 50px;
  }

  &:hover {
    @media(min-width: $lg) {
      color: $Corporate-2;
    }
  }

  &:not(:disabled):active {
    opacity: 0.7;
  }

  svg {
    display: block;
    width: 28px;
    height: 28px;
    stroke: currentcolor;
  }

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}
